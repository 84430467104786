import React, {useState, useEffect}from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { Signout } from '../Signout/Signout'
import { BorrarUser } from '../BorrarUser/BorrarUser'
import "./Usuario.css"
import { getSubscriptionStatus} from '../../firebase/config'
import { goStripePortal } from '../../firebase/portal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faStar, faXmark, faAngleDown, faAngleUp, faCrown} from '@fortawesome/free-solid-svg-icons'


export const Usuario = () => {

  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState({})
  const [state, setState] = useState(0);
  const [premium, setPremium] = useState([]);
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    // Lógica para obtener las alertas del usuario actual al cargar el componente
    const fetchSubs = async () => {
      try {
        if (currentUser && currentUser.uid) {
          const isPremium = await getSubscriptionStatus(currentUser.uid);
          setPremium(isPremium);
        }
      } catch (error) {
        console.error('Error al obtener las suscripciones:', error);
      }
    };
  
    fetchSubs();
  }, [currentUser]);

  useEffect(() => {
    // Este efecto se ejecutará cada vez que currentUser cambie
    if (premium === true) {
      setState(7);
    }
  }, [currentUser, premium]);

  async function goStripePortalWithLoading() {
    try {
      setLoading(true); // Activar el indicador de carga
      await goStripePortal(); // Llamar a la función para ir al portal de Stripe
    } catch (error) {
      console.error('Error al ir al portal de Stripe:', error);
      alert('Ocurrió un error durante el proceso. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false); // Desactivar el indicador de carga después de completar
    }
  }
  

  function handleUserPremium(user){
    setCurrentUser(user)
    setState(7)
  }

  function handleUserLoggIn(user){
    setCurrentUser(user)
    setState(2)
  }

  function handleUserNotRegistered(user){
      navigate('/login')
  }

  function handleUserNotLoggIn(){
      navigate('/login')
  }

  function goToFavoritos(){
    navigate('/favoritos')
  }
  function goToAlertas(){
    navigate('/alertas')
  }

  function goToPremium(){
    navigate('/premium')
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen); 
  }

  if(state === 2){
    return(
      <div>
          {loading && (
            <div className="loading-overlay">
              <div className="loader"></div>
            </div>
            )}
        <div className='usuario'>
          <div className='info-y-botones'>
            <div className='info-user'>
              <div className='user-plan'>
                <h1>{currentUser.displayName}</h1>
                <span className='premium-label'>Gratis</span>
              </div>
              <p className='puser'>{currentUser.username}</p>
            </div>
            <div className='servicios-premium user-h2'>
              <h2>Servicios Premium</h2>
              <ul>
                <li><p className='puser'>Suscribete para disfrutar de los servicios Premium!</p></li>
                <li><button className="suscribe-premium" onClick={goToPremium}><p><FontAwesomeIcon icon={faCrown} />Suscribirse</p></button></li>
                <li><button className="go-premium" onClick={goToAlertas}><p><FontAwesomeIcon icon={faBell} />Mis Alertas</p></button></li>
                <li><button className="go-premium" onClick={goToPremium}><p><FontAwesomeIcon icon={faStar} />Productos Favoritos</p></button></li>
              </ul>
            </div>
            <div className='botones-user user-h2'>
              <h2 onClick={toggleDropdown} className='dropdown-toggle'>Gestionar Cuenta
              <span>{isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</span>
              </h2>
              {isOpen && (
                <ul className='dropdown-list'>
                  <li><BorrarUser usuario={currentUser} /></li>
                </ul>
              )}
            </div>
            <Signout/>
          </div>
          <img className='img-perfil' src="/imgs/perfil.jpg" alt="persona con bolsa para comprar" />
        </div>
      </div>
    );
  }

  if(state === 7){
    return(
      <div>
          {loading && (
            <div className="loading-overlay">
              <div className="loader"></div>
            </div>
            )}
        <div className='usuario'>
          <div className='info-y-botones'>
            <div className='info-user'>
              <div className='user-plan'>
                <h1>{currentUser.displayName}</h1>
                <span className='premium-label'>Premium</span>
              </div>
              <p className='puser'>{currentUser.username}</p>
            </div>
            <div className='servicios-premium user-h2'>
              <h2>Servicios Premium</h2>
              <ul>
                <li><button onClick={goToAlertas}><p><FontAwesomeIcon icon={faBell} />Mis Alertas</p></button></li>
                <li><button onClick={goToFavoritos}><p><FontAwesomeIcon icon={faStar} />Productos Favoritos</p></button></li>
              </ul>
            </div>
            <div className='botones-user user-h2'>
              <h2 onClick={toggleDropdown} className='dropdown-toggle'>Gestionar Cuenta
              <span>{isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</span>
              </h2>
              {isOpen && (
                <ul className='dropdown-list'>
                  <li>  
                    <button onClick={goStripePortalWithLoading} disabled={loading}>
                      <p><FontAwesomeIcon icon={faXmark} /> Cambiar/Cancelar Plan</p>
                    </button>
                  </li>
                  <li><BorrarUser usuario={currentUser} /></li>
                </ul>
              )}
            </div>
            <Signout/>
          </div>
          <img className='img-perfil' src="/imgs/perfil.jpg" alt="persona con bolsa para comprar" />
        </div>
      </div>
    );
  }
  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserPremium={handleUserPremium}
      >
    </AuthProvider>
  );

}
