import React from 'react'
import "./Nosotros.css"
import { Contacto } from '../Contacto/Contacto'
import { Link } from 'react-router-dom'

export const Nosotros = () => {
  return (
    <div className="div-nosotros">
      <img className='img-nosotros' src="/imgs/ropa.jpg" alt="ropa en perchas" />
      <div className="div-texto">
        <h1>Sobre Nosotros</h1>

        <div className="about-section">
          <h2>Redefiniendo la Experiencia de Compra de Moda Segunda Mano</h2>
          <p>En eRastro, estamos comprometidos con cambiar la forma en que experimentas la moda de segunda mano. Inspirados por el carácter único del Rastro de Madrid, hemos creado una plataforma digital que reúne prendas exclusivas de diversas fuentes en un solo lugar. Con nosotros, descubre la simplicidad y la emoción de encontrar moda sostenible y llena de historia, todo desde la comodidad de tu pantalla. ¡Bienvenido a una nueva era de moda única y accesible!</p>
        </div>
        <div>
          <Contacto/>
        </div>
        <div className='paginas-legales'>
          <Link to="/aviso-legal">Aviso legal</Link>
          <Link to="/politica-cookies">Política de Cookies</Link>
          <Link to="/politica-privacidad">Política de privacidad</Link>
        </div>
      </div>
    </div>
  )
}
