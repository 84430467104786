import React, {useState, useEffect}from 'react'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { removeFromFavorites, addToFavorites} from '../../firebase/config'
import { Link } from 'react-router-dom'
import { upperFL } from '../../utils/utils'
import "./Producto.css"
import Productos from '../Productos/Productos';

export const Producto = ( {hit, premium} ) => {

  /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    6: nuevo username: click para continuar
    */
  
  const [state, setState] = useState(0);
  const [currentUser, setCurrentUser] = useState({})
  const [userLikes, setUserLikes] = useState([]);


  useEffect(() => {
		// Este efecto se ejecutará cada vez que currentUser cambie
		if (premium) {
			setState(7);
		}

	}, [currentUser]);

  function handleUserPremium(user){
    setCurrentUser(user)
    setState(7)
    
  }

  function handleUserLoggIn(user){
      setCurrentUser(user)
      setState(2)
  }

  function handleUserNotRegistered(user){
      setState(4)
  }

  function handleUserNotLoggIn(){
      setState(4)
  }

  // Efecto para actualizar los likes locales cuando cambia el usuario
  useEffect(() => {
    if (currentUser && currentUser.likes) {
      setUserLikes(currentUser.likes);
    }
  }, [currentUser]);

  const handleLikeClick = async (hit) => {
    if (currentUser) {
      try {
        if (userLikes.includes(hit.objectID)) {
          // Si ya está en favoritos, elimínalo
          await removeFromFavorites(currentUser.uid, hit.objectID);
        } else {
          // Si no está en favoritos, añádelo
          await addToFavorites(currentUser.uid, hit.objectID);
        }

        // Actualiza el estado local con los likes actualizados
        setUserLikes((prevLikes) =>
          prevLikes.includes(hit.objectID)
            ? prevLikes.filter((like) => like !== hit.objectID)
            : [...prevLikes, hit.objectID]
        );
      } catch (error) {
        console.error('Error al manejar el like:', error);
      }
    }
  }

  if(state===2){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3>{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Plataforma:</strong> {upperFL(hit.web)}</li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a href={hit.link} target="_blank" rel="noopener noreferrer">
                  <button>
                    Ver Producto
                  </button>
                </a>
                <Link to="/premium">
                  <button className='boton-favoritos'>
                    {'Hazte Premium para agregar favoritos'}
                  </button>
                </Link>
              </div>
              
          </div>
      </div>
    )
  }

  if(state === 4){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3>{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Plataforma:</strong> <Link to="/login">Login para ver</Link></li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <Link to="/login"><button>Login para ver el producto</button></Link>
              </div>
          </div>
      </div>
    )
  }
  if(state===7){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3>{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
              <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Plataforma:</strong> {upperFL(hit.web)}</li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a href={hit.link} target="_blank" rel="noopener noreferrer">
                  <button>
                    Ver Producto
                  </button>
                </a>
                <button 
                 className={`boton-favoritos ${userLikes.includes(hit.objectID) ? 'quitar' : 'agregar'}`}
                 onClick={() => handleLikeClick(hit)}>
                  {userLikes.includes(hit.objectID) ? 'Quitar de favoritos' : 'Agregar a favoritos'}
                </button>
              </div>
          </div>
      </div>
    )
  }



  return (
    <AuthProvider 
    onUserLoggIn={handleUserLoggIn}
    onUserNotLoggIn={handleUserNotLoggIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserPremium={handleUserPremium}
    >

    </AuthProvider>
  )
}

export default Producto
