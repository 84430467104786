import React, { useState } from 'react'
import {auth} from "../../firebase/config"
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import './Login.css';


export const Login = () => {

    /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    */
    const navigate = useNavigate()
    const [state, setCurrentState] = useState(0);

    async function handleOnClick(){
        const googleProvider = new GoogleAuthProvider()
        await signInWithGoogle( googleProvider)

        async function signInWithGoogle(googleProvider){
            try {
                const res = await signInWithPopup(auth, googleProvider);
                //console.log(res)
            } catch (error) {
                console.error(error)
            }
        }
    }
    function handleUserPremium(user){
        setCurrentState(7)
        navigate('/')
        
    }

    function handleUserLoggIn(user){
        navigate('/')
    }

    function handleUserNotRegistered(){
        navigate('/elige-usuario')
    }

    function handleUserNotLoggIn(user){
        setCurrentState(4)
    }

    if(state === 1){
        return <div>Loading...</div>
    }

    if (state === 4) {
        return (
        <div className='login'>
            <div className='login-texto'>
                <h1>Iniciar sesión con Google</h1>
                <p>Inicia sesión para desbloquear funciones exclusivas y disfrutar de ventajas especiales:</p>
                <ul>
                    <li>Ver en que páginas web puedes comprar los productos</li>
                    <li>Tener acceso al link de los productos para verlos en detalle</li>
                </ul>
                <button className='boton-login' onClick={handleOnClick}>Login with Google</button>
            </div>
            <img className='img-login' src="/imgs/login.jpeg" alt="chica mirando ropa en un baul" />
        </div>
        
        );
    }

    if (state === 5) {
        return (
        <div className='login'>
            <h1>Iniciar sesión con google</h1>
            <button className='boton-login' onClick={handleOnClick}>Login with Google</button>
        </div>
        );
    }

  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserPremium={handleUserPremium}
      >
        <div>Loading...componente</div>
    </AuthProvider>
  );
}
