import React, {useState, useEffect}from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { getFavoritesByUserId, getSubscriptionStatus } from '../../firebase/config'
import ProductosFav from '../ProductosFav/ProductosFav';
import './Favoritos.css';

export const Favoritos = () => {

  /*
  Stages:
  0: initiated
  1: loading
  2: login completed
  3: login but no username
  4: not logged
  5: ya existe el username
  6: nuevo username: click para continuar
  7: Usuario Premium
  */
  const navigate = useNavigate()
  const [state, setState] = useState(0);
  const [currentUser, setCurrentUser] = useState({})
  const [premium, setPremium] = useState([]);

  useEffect(() => {
    // Lógica para obtener las alertas del usuario actual al cargar el componente
    const fetchSubs = async () => {
      try {
        if (currentUser && currentUser.uid) {
          const isPremium = await getSubscriptionStatus(currentUser.uid);
          setPremium(isPremium);
        }
      } catch (error) {
        console.error('Error al obtener las suscripciones:', error);
      }
    };
  
    fetchSubs();
  }, [currentUser]);

  useEffect(() => {
    // Este efecto se ejecutará cada vez que currentUser cambie
    if (premium === true) {
      setState(7);
    }
  }, [currentUser, premium]);

  function handleUserPremium(user){
    setCurrentUser(user)
    setState(7)
    
  }

  function handleUserLoggIn(user){
  setCurrentUser(user)
  setState(2)
  }

  function handleUserNotRegistered(user){
      navigate('/login')
      setState(3)
  }

  function handleUserNotLoggIn(){
      navigate('/login')
  }
  
  const [userLikes, setUserLikes] = useState([]);
  // Efecto para cargar los favoritos del usuario cuando cambia el usuario
  useEffect(() => {
    const fetchFavorites = async () => {
      if (currentUser) {
        try {
          const items = (currentUser.likes)
          const favorites = await getFavoritesByUserId(items);
          setUserLikes(favorites);
          
        } catch (error) {
          console.error('Error al obtener favoritos:', error);
        }
      }
    };

    fetchFavorites();
  }, [currentUser]);
  //===================

  if(state ===7){
    return (
      <div className='fav-goblal-container'>
        <p className='alert'>Si quita algún producto de favoritos y recarga la página tendrá que buscar el producto otra vez desde el buscador</p>
        <div className='container-fav'>
          <h1>Mis Productos Favoritos</h1>
        </div>
        {userLikes.length > 0 ? (
          <div className='products-container'>
            {userLikes.map((favorite) => (
              <ProductosFav key={favorite.id} producto={favorite} />
            ))}
          </div>
        ) : (
          <div className='container-fav'>
            <p>Empieza a añadir tus productos favoritos.</p>
            <button><Link to='/' className="buscar-productos-link">Buscar productos</Link></button>
          </div>
          
        )}
      </div>
    );
  }

  return (
    <AuthProvider 
    onUserLoggIn={handleUserLoggIn}
    onUserNotLoggIn={handleUserNotLoggIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserPremium={handleUserPremium}
    >

    </AuthProvider>
  )
}
