import React, { useState, useEffect } from 'react';
import { AuthProvider } from '../AuthProvider/AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import { updateUser, usernameExists } from '../../firebase/config';
import './EligeUsuario.css';

export const EligeUsuario = () => {
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [currentUser, setCurrentUser] = useState({});
    const [username, setUsername] = useState('');
    const [gender, setGender] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();

        setMaxDate(`${yyyy}-${mm}-${dd}`);
    }, []);

    function handleUserPremium(user) {
        setCurrentUser(user);
        setState(7);
        navigate('/');
    }

    function handleUserLoggIn(user) {
        navigate('/');
    }

    function handleUserNotRegistered(user) {
        setCurrentUser(user);
        setState(3);
    }

    function handleUserNotLoggIn() {
        navigate('/login');
    }

    function handleInputUsername(e) {
        setUsername(e.target.value);
    }

    function handleInputGender(e) {
        setGender(e.target.value);
    }

    function handleInputBirthdate(e) {
        setBirthdate(e.target.value);
    }

    async function handleContinue(e) {
        e.preventDefault(); // Previene el comportamiento por defecto del botón de submit
        if (username !== '' && gender !== '' && birthdate !== '') {
            const exists = await usernameExists(username);
            if (exists) {
                setState(5);
            } else {
                const tmp = { ...currentUser };
                tmp.username = username;
                tmp.gender = gender;
                tmp.birthdate = birthdate;
                tmp.processCompleted = true;
                await updateUser(tmp);
                setState(6);
            }
        }
    }

    if (state === 3 || state === 5) {
        return (
            <div className="registro-container">
                <h1>Bienvenido <span className="display-name">{currentUser.displayName}</span></h1>
                <p>Para terminar el proceso de registro, completa la información:</p>
                {state === 5 ? <p className="error-message">El nombre de usuario ya existe, elige otro.</p> : ''}
                <form onSubmit={handleContinue}>
                    <div className="input-container">
                        <label>Nombre de usuario:</label>
                        <input type="text" onInput={handleInputUsername} className="username-input" required />
                    </div>
                    <div className="input-container">
                        <label>Fecha de nacimiento:</label>
                        <input type="date" onChange={handleInputBirthdate} className="birthdate-input" max={maxDate} required />
                    </div>
                    <div className="input-container">
                        <label>Género:</label>
                        <select onChange={handleInputGender} className="gender-select" required>
                            <option value="">Selecciona tu género</option>
                            <option value="male">Hombre</option>
                            <option value="female">Mujer</option>
                            <option value="other">Prefiero no decirlo</option>
                        </select>
                    </div>
                    <div className="button-container">
                        <button type="submit" className="continue-button">Continuar</button>
                    </div>
                </form>
            </div>
        );
    }

    if (state === 6) {
        return (
            <div className="registro-exitoso">
                <h1>Felicidades, te has registrado correctamente. Ahora puedes buscar productos.</h1>
                <button><Link to='/' className="buscar-productos-link">Buscar productos</Link></button>
            </div>
        );
    }

    return (
        <AuthProvider
            onUserLoggIn={handleUserLoggIn}
            onUserNotLoggIn={handleUserNotLoggIn}
            onUserNotRegistered={handleUserNotRegistered}
            onUserPremium={handleUserPremium}
        >
        </AuthProvider>
    );
};
